import React from 'react'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import theme from './_theme'
import styles from './_styles'

import { Box, Text, Card } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Header from '@solid-ui-blocks/Educabot/Header/header'
import favicon from '../../../../../site/content/assets/favicon.png'
import Footer from '@solid-ui-blocks/Educabot/Footer/Block01'
import Block07 from '@solid-ui-blocks/Educabot/Codit/Block07'
import { FormContact } from '../../../../solid-ui/solid-ui-components/src/FormContact/FormContact'
import Block01 from '../../../../solid-ui/solid-ui-blocks/src/Educabot/Partners/PartnerCards/Block01'
import Reveal from '@solid-ui-components/Reveal'


const IndexPage = props => {
    const { allBlockContent } = props.data
    const content = normalizeBlockContentNodes(allBlockContent?.nodes)
    const webformData = 'https://webforms.pipedrive.com/f/6iwRmg6AHyhK19rNYaa1tBQiaRJXbsigA1MSHj0vfUZHoHaIoV8GIpr2ckE05cp6sX';

    return (
        <Layout theme={theme} {...props}>
            <Seo title='EDUCABOT' />

            <Helmet>
                <link rel="icon" href={favicon} />
            </Helmet>

            <Header content={content['header']} />

            <Box sx={styles.partnersCtn}>
                <Reveal effect='fadeInUp'>
                    <Card sx={styles.pill}>Distribuidores</Card>
                </Reveal>

                <Reveal effect='fadeInLeft'>
                    <Text sx={styles.partnerTitle}>Conocé a nuestros partners</Text>
                </Reveal>

                <Reveal effect='fadeInLeft'>
                    <Text sx={styles.partnerSubtitle}>Quiénes nos ayudan a llevar nuestros productos al mundo.</Text>
                </Reveal>

                <Reveal effect='fadeInRight'>
                    <Block01 content={content['partners']} />
                </Reveal>
            </Box>

            <Box sx={styles.container}>
                <Box sx={styles.formCtn}>
                    <Box sx={styles.formSection} id="coditForm">
                        <FormContact webformData={webformData} />
                    </Box>
                </Box>
            </Box>

            <Box sx={styles.footerSection}>
                <Block07 content={content['coditFooter']} />
            </Box>

            <Footer content={content['footer']} />
        </Layout>
    )
}

export const query = graphql`
  query partnersBlockContent {
    allBlockContent(
      filter: { page: { in: ["homepage/educabot", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
