/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

import bgContact from '../../../../../site/content/assets/flexiblocks/codit/background-form.svg'

 export default {
   header: {
     bg: '#27A8FF',
     pt: '1rem',
   },
   contact: {
     bg: '#2A205E',
     '@media (max-width: 625px)': {
        paddingBottom: '8rem',
      },
      '@media (max-width: 485px)': {
        paddingBottom: '11rem',
      }
   },
   firstSections: {
    bg: '#FFFFFF',
    pt: '2rem'
  },
  heroOne: {
    bg: '#FFFFFF',
    pt: '6rem',
    '@media (max-width: 991px)': {
     pt: '2rem'
   }
  },
   sections: {
     bg: '#FFFFFF',
     pt: '9rem',
     '@media (max-width: 991px)': {
      pt: '2rem'
    }
   },
   heroTwo: {
    bg: '#FFFFFF',
    pt: '9rem',
    paddingBottom: '100px',
    '@media (max-width: 991px)': {
     pt: '2rem'
   }
  },
   sectionSuite: {
    bg: '#F7F8FC',
    pt: '4rem',
    pb: '4rem',
    '@media (max-width: 991px)': {
     pt: '2rem'
   }
  },
   heroThree: {
    bg: '#FFFFFF',
    pt: '9rem',
    pb: '4rem',
    '@media (max-width: 991px)': {
     pt: '2rem'
   }
  },
   whyChooseUs: {
     bg: '#FFFFFF',
     pt: '2rem',
     pb: '5rem'
   },
   features: {
     bg: '#FFFFFF',
     pt: '1rem',
     paddingBottom: '5rem'
   },
   testimonials: {
     bg: '#FFFFFF',
     pt: '8rem'
   },
   trustUs: {
     bg: '#FFFFFF',
     pt: '4rem',
     '@media (max-width: 1005px)': {
        pb: '4rem',
     },
     '@media (max-width: 638px)': {
      pb: '10rem',
   },
   },
   pictures: {
    pb: '3rem'
  },
   title: {
    fontSize: 40,
    fontWeight: 600,
    lineHeight: '50px',
    color: '#2A205E',
    textAlign: 'center',
    pt: '1rem',
    paddingBottom: '2rem',
    '@media (max-width: 765px)': {
      fontSize: 30,
      textAlign: 'center',
      padding: '5px'
    },
    '@media (max-width: 450px)': {
      lineHeight: '40px',
      marginBottom: '1rem'
    },
  },
  subTitle: {
    width: '80%',
    margin: 'auto',
    fontSize: '18px',
    '@media (max-width: 560px)': {
      textAlign: 'center'
    },
  },
  container: {
    backgroundColor: '#42B3FF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '50px 0',
    overflow: 'hidden',
    marginBottom: 60,
    '@media (min-width: 700px)': {
        backgroundImage: `url(${bgContact})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
    },
    '@media (min-width: 1400px)': {
        padding: '80px 0',
        backgroundSize: 'cover',
        backgroundPosition: 'center 10px',
    },
  },
  formCtn: {
    width: '100%',
    maxWidth: 300,
    overflow: 'hidden',
    backgroundColor: '#FFF',
    padding: 20,
    borderRadius: 8,
    '@media (min-width: 700px)': {
        maxWidth: 'auto',
    },
    '@media (min-width: 1000px)': {
        width: 500,
        maxWidth: 500,
    }
  },
  formSection: {
    bg: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (min-width: 1000px)': {
        display: 'block',
        textAlign: 'center',
    }
  },
  partnersCtn:{
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden',
    padding: '45px 20px 80px 20px',
    '@media (min-width: 800px)': {
        maxWidth: '1230px',
        margin: 'auto',
        padding: '80px 20px 180px 20px',
    }
  },
  pill: {
    display: 'inline-block',
    fontSize: 14,
    fontWeight: 500,
    color: '#42B3FF',
    border: '1px solid #42B3FF',
    borderRadius: 8,
    padding: '4px 14px',
    boxSizing: 'border-box',
    backgroundColor: 'rgba(66, 179, 255, 0.05)',
  },
  partnerTitle: {
    fontWeight: 700,
    color: '#2A205E',
    fontSize: 25,
    margin: '15px 0 15px 0',
    '@media (min-width: 800px)': {
        fontSize: 42,
        margin: '12px 0 4px 0',
    }
  },
  partnerSubtitle: {
    color: '#6D7878',
    fontWeight: 400,
    fontSize: 18,
    marginBottom: '45px',
    '@media (min-width: 800px)': {
        fontSize: 27,
    }
  },
 }