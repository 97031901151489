import React from 'react'
import { Box, Text, Link } from 'theme-ui'

const styles = {
    container: {
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 30,
        '@media (min-width: 800px)': {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
        }
    },
    card: {
        boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
        borderRadius: 8,
        borderTopColor: '#42B3FF',
        borderTopWidth: 10,
        borderTopStyle: 'solid',
    },
    content: {
        boxSizing: 'border-box',
        overflow: 'hidden',
        padding: 15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 15,
        backgroundColor: '#FFF',
    },
    logoCtn: {
        minWidth: '80px',
        minHeight: '80px',
        overflow: 'hidden',
        '@media (min-width: 800px)': {
            minWidth: '160px',
            minHeight: '160px',
        },
        '& img': {
            width: '100%',
            height: '100%',
        }
    },
    infoCtn: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    },
    title: {
        fontWeight: 700,
        fontSize: 18,
        color: '#2A205E',
        '@media (min-width: 800px)': {
            fontSize: 27,
        }
    },
    link: {
        fontWeight: 400,
        fontSize: 12,
        color: '#4F36D6',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        '& img': {
            width: '18px',
        },
        '@media (min-width: 800px)': {
            fontSize: 14,
        }
    },
    flagsCtn: {
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'flex-start',
        gap: '10px',
        flexWrap: 'wrap',
        '@media (min-width: 800px)': {
            height: '58px',
        },
        '& img': {
            width: '28px',
            height: '20px',
            '@media (min-width: 800px)': {
                width: '32px',
                height: '24px',
            }
        },
    }
}

const Block01 = ({ content }) => {

    const { collection: collections } = content;
    
    return (
        <Box sx={styles.container}>
            {
                collections.map( collection => {

                    const { container, images, text, icon } = collection;
                    const [logo, ...flags] = images;
                    const [{ text: title }] = text;

                    const logoImage = logo.src.publicURL;
                    const partnerLink = container.link.link;
                    const formattedPartnerLink = partnerLink.split('www.')[1].slice(0, -1);
                    
                    return (
                        <Box sx={styles.card}>
                            <Box sx={styles.content}>
                                <Box sx={styles.logoCtn}>
                                    <img src={logoImage} alt='Logo image' />
                                </Box>

                                <Box sx={styles.infoCtn}>
                                    <Text sx={styles.title}>{title}</Text>

                                    <Box sx={styles.flagsCtn}>
                                        {
                                            flags.map( flag => (
                                                <img src={flag.src.publicURL} alt='Flag image' />
                                            ))
                                        }
                                    </Box>

                                    <Link sx={styles.link} href={partnerLink} target='_blank'>
                                        <img src={icon.src} alt='Icon' />
                                        {formattedPartnerLink}
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    );
                    
                } )
            }
        </Box>
    );

}

export default Block01